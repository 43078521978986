import React from 'react';
import { FormControl, Icon, VStack } from 'native-base'; //WEB99 Form
import {
  View,
  Dimensions,
  Text,
  StyleSheet,
  Keyboard,
  TouchableOpacity,
  Linking,
} from 'react-native';
import { Actions } from 'react-native-router-flux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import I18n from 'i18next';
import { Calendar } from 'react-native-calendars';
import Icon2 from 'react-native-vector-icons/MaterialIcons';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import Config from 'react-native-config';
import {
  actRegister,
  listenerToMyProfile,
  actLogout,
} from '../../actions/AuthActions';
import AppMem from '../../common/AppMem';
import L from '../../common/Layout';
import Colors from '../../common/Colors';
import Fonts from '../../common/Fonts';
import Util from '../../common/Util';
import { required, validateEmail, password } from '../../common/Validations';
import renderInput from '../../components/LabelCustomInput';
import { saveDNIDataToUser } from '../../actions/AuthActions';
import MainButton from '../../components/MainButton';
import UserExistPopUp from '../../components/UserExistPopUp';
import moment from 'moment';
import VTTermsModal from '../../components/VTTermsModal';
import Loader from '../../components/Loader';
import toast from 'react-hot-toast';

let screen = Dimensions.get('window');
const BG_IMG = require('../../common/assets/background_login.jpg');

class Register extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputs = {};
    this.state = {
      selected: null,
      check: false,
      loading: false,
    };
    this.onDayPress = this.onDayPress.bind(this);
    this.onRegisterSuccess = this.onRegisterSuccess.bind(this);
    this.onRegisterError = this.onRegisterError.bind(this);
    this.onPressCheck = this.onPressCheck.bind(this);
    this.onPressAcceptTerms = this.onPressAcceptTerms.bind(this);
    this.onLogoutPress = this.onLogoutPress.bind(this);
  }

  onPressAcceptTerms(formValues) {
    let form = formValues;
    AppMem.closeModal();
    this.setState({ loading: true });
    this.props.actRegister(form, this.onRegisterSuccess, this.onRegisterError);
  }

  async componentWillMount() {
    if (Util.isIos() && this.props.iosFaceID) {
      //   console.log(" >>>>>>>>>> Util.getCurrentUser", this.props.iosEmail);
      this.props.initialize({
        email: this.props.iosEmail,
      });
    }
    setTimeout(() => {
      AppMem.closeAppLoader();
    }, 1000);
  }

  onRegisterPress(formValues) {
    //  Keyboard.dismiss();
    if (this.state.check) {
      this.onPressAcceptTerms(formValues);
    } else {
      toast.error('Tenés que aceptar los TÉRMINOS Y CONDICIONES');
    }
  }

  onRegisterSuccess(user) {
    this.setState({ loading: false });
    this.props.dispatch(reset('register'));
    // this.props.listenerToMyProfile(
    //   this.props.iosFaceID ? Util.getCurrentUID() : user._user.uid
    // );
    toast.success('Registro Exitoso!');
    Actions.replace('activateAccount');
  }

  onRegisterError(error) {
    this.setState({ loading: false });
    if (error) {
      let message = '';
      console.log(' >>>>>>>>>> error ', error);
      // this.set;
      if (error.code !== 'user-exist') {
        switch (error.code) {
          case 'auth/wrong-password':
            message = I18n.t('login.wrongPassword');
            break;
          case 'auth/user-not-found':
            message = I18n.t('login.userNotRegistered');
            break;
          case 'auth/invalid-email':
            message = I18n.t('login.invalidEmail');
            break;
          case 'auth/weak-password':
            message = I18n.t('login.weakPassword');
            break;

          case 'auth/email-already-in-use':
            message = I18n.t('login.emailInUse');
            break;
          case 'auth/network-request-failed':
            message = I18n.t('login.verifyYourConection');
            break;
          default:
            message = I18n.t('login.verifyYourConection');
            break;
        }
        toast.error(message);
      } else {
        let message =
          I18n.t('userExistPopUp.mainMessage', {
            message: 'DNI',
          }) +
          I18n.t('userExistPopUp.mainMessage2') +
          ' ' +
          Config.EMAIL_INFO_APP;
        toast.error(message);
      }
    }
  }

  static onRegisterSuccess() {
    AppMem.closeModal();
    let modalProps = {
      title: I18n.t('register.successRegisterText'),
      type: 'success',
      buttonOk: { text: I18n.t('register.accept'), action: () => null },
    };
    AppMem.openModal('alert', modalProps);
    Actions.login();
  }

  getInputProps() {
    let props = {
      borderColor: 'white',
      labelStyle: { color: 'white' },
      inputStyle: { color: 'white' },
      errorIconStyle: { color: 'white' },
      borderBottomColor: 'white',
      blurOnSubmit: false,
      withRef: true,
      placeholderStyle: { color: 'white' },
      placeholder: 'Completar',
    };
    return props;
  }

  // focusNext(key) {
  //   if (!this.props.registerValues[key]) {
  //     // verifica que si el siguiente input tiene un dato
  //     // si no tiene dato hace el next
  //     this.inputs && this.inputs[key].getRenderedComponent().executeFocus();
  //   } else {
  //     // si lo tiene cierra el teclado y no hace el next
  //     Keyboard && Keyboard.dismiss();
  //   }
  // }

  openCalendar() {
    return (
      <Calendar
        onDayPress={this.onDayPress}
        style={localStyle.calendar}
        hideExtraDays
        markedDates={{
          [this.state.selected]: {
            selected: true,
            disableTouchEvent: true,
            selectedDotColor: 'orange',
          },
        }}
      />
    );
  }

  onDayPress(day) {
    this.setState({
      selected: day.dateString,
    });
  }
  onLogoutPress = () => {
    // AppMem.openModal("spinner");
    this.props.actLogout(() => {
      Actions.reset('login');
      // AppMem.closeModal("spinner");
    });
  };

  openUrl() {
    const url = 'https://keops-dev.web.app/tyc-v2'; // Reemplaza con tu URL
    Linking.openURL(url);
  }

  onPressCheck() {
    this.setState({ check: !this.state.check });
  }

  render() {
    const { handleSubmit, appSettings } = this.props;
    const { loading } = this.state;
    let mayorEdad = moment().subtract(18, 'years');
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.backgroundHistory,
          paddingTop: 140,
        }}
      >
        <section
          class=""
          style={{
            marginLeft: '18%',
            marginRight: '18%',
          }}
        >
          <div class="container">
            <div class="article-section padding-bottom">
              <div class="section-header-1" style={{}}>
                <h2 class="title">{I18n.t('register.registerTitle')}</h2>
              </div>
              <h5 class="mb-10">
                Registrate, comprá tus tickets y disfrutá de todos los
                beneficios!
              </h5>
              <View style={localStyle.formContainer}>
                <div class="row">
                  <div class="col-sm-12 col-lg-6">
                    <Field
                      name="proName"
                      label={I18n.t('register.registerNameLabel')}
                      ref={input => {
                        this.inputs.name = input;
                      }}
                      component={renderInput}
                      iconColor={Colors.black_87}
                      validate={required}
                      returnKeyType="next"
                      onSubmitEditing={() => this.focusNext('lastName')}
                      autoCapitalize="words"
                      {...this.getInputProps()}
                    />
                  </div>
                  <div class="col-sm-12 col-lg-6">
                    <Field
                      name="proLastName"
                      label={I18n.t('register.registerSurnameLabel')}
                      ref={input => {
                        this.inputs.lastName = input;
                      }}
                      validate={required}
                      component={renderInput}
                      returnKeyType="next"
                      onSubmitEditing={() => this.focusNext('dni')}
                      autoCapitalize="words"
                      {...this.getInputProps()}
                    />
                  </div>
                </div>
                <div class="row" style={{ marginTop: 15 }}>
                  <div class="col-sm-12 col-lg-6">
                    <VStack w="100%">
                      <Field
                        name="proDniType"
                        itemType="ItemList"
                        type="picker"
                        ref={input => {
                          this.inputs['proDniType'] = input;
                        }}
                        label="Tipo de documento (opcional)"
                        //validate={required}
                        component={renderInput}
                        placeholderStyle={{ color: 'white' }}
                        items={appSettings?.documentTypes?.map((ed, i) => ({
                          label: ed,
                          value: ed,
                        }))}
                        {...this.getInputProps()}
                        placeholder="Seleccione el tipo de documento"
                        //disabled={socialMedia && verifiedUser}
                      />
                    </VStack>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                    <Field
                      name="proDNI"
                      label={I18n.t('register.registerDniLabel')}
                      ref={input => {
                        this.inputs.dni = input;
                      }}
                      //validate={required}
                      component={renderInput}
                      returnKeyType="next"
                      //  onSubmitEditing={() => Keyboard.dismiss()}
                      autoCapitalize="words"
                      {...this.getInputProps()}
                    />
                  </div>
                </div>
                {/* <div class="row" style={{ marginTop: 15 }}>
                    <div class="col-sm-12 col-lg-6">
                      <Field
                        name="birthDate"
                        label={I18n.t('register.birthDate')}
                        borderColor={Colors.rouge}
                        ref={input => {
                          this.inputs.birthDate = input;
                        }}
                        validate={[required]}
                        component={renderInput}
                        itemType="DatePicker"
                        dateProps={{
                          maxDate: mayorEdad,
                          format: 'DD [de] MMMM YYYY',
                        }}
                        {...this.getInputProps()}
                        onChangeDate={() => this.focusNext('gender')}
                      />
                    </div>
                    <div class="col-sm-12 col-lg-6">
                      <Field
                        name="gender"
                        itemType="ItemList"
                        type="picker"
                        placeholderStyle={{ color: 'white' }}
                        ref={input => {
                          this.inputs.gender = input;
                        }}
                        label={I18n.t('register.registerGenderLabel')}
                        borderColor={Colors.rouge}
                        //validate={required}
                        component={renderInput}
                        items={[
                          {
                            label: I18n.t('register.registerGenderManLabel'),
                            value: 'male',
                          },
                          {
                            label: I18n.t('register.registerGenderWomLabel'),
                            value: 'female',
                          },
                        ]}
                        {...this.getInputProps()}
                        placeholder="Seleccione un género"
                      />
                    </div>
                  </div> */}
                <div class="row" style={{ marginTop: 15 }}>
                  <div class="col-sm-12 col-lg-6">
                    <Field
                      name="proEmail"
                      label={I18n.t('register.registerEmailLabel')}
                      ref={input => {
                        this.inputs.email = input;
                      }}
                      //editable={Util.isIos() && this.props.iosEmail}
                      component={renderInput}
                      validate={[validateEmail, required]}
                      autoCapitalize="none"
                      returnKeyType="next"
                      onSubmitEditing={
                        this.props.iosFaceID
                          ? handleSubmit(this.onRegisterPress.bind(this))
                          : () => this.focusNext('password')
                      }
                      {...this.getInputProps()}
                    />
                  </div>
                  {/* <div class="col-sm-12 col-lg-6">
                      <Field
                        name="proInstagram"
                        label={'Instagram (opcional)'}
                        // ref="instagram"
                        ref={input => {
                          this.inputs.instagram = input;
                        }}
                        trimWhiteSpace
                        component={renderInput}
                        //validate={[required]}
                        //keyboardType=""
                        returnKeyType={'next'}
                        //secureTextEntry
                        onSubmitEditing={handleSubmit(this.onRegisterPress)}
                        {...this.getInputProps()}
                      />
                    </div> */}
                  <div class="col-sm-12 col-lg-6">
                    <Field
                      name="proPassword"
                      label={I18n.t('register.registerPasswordLabel')}
                      ref={input => {
                        this.inputs.password = input;
                      }}
                      type="password"
                      validate={[password, required]}
                      component={renderInput}
                      returnKeyType="send"
                      autoCapitalize="none"
                      secureTextEntry
                      onSubmitEditing={handleSubmit(
                        this.onRegisterPress.bind(this)
                      )}
                      {...this.getInputProps()}
                    />
                  </div>
                </div>
                <div class="row" style={{ marginTop: 15 }}>
                  {/* <div class="col-sm-12 col-lg-6">
                      <Field
                        name="proPhone"
                        label={'Número de teléfono'}
                        //ref="phone"
                        ref={input => {
                          this.inputs.phone = input;
                        }}
                        trimWhiteSpace
                        component={renderInput}
                        validate={[required]}
                        keyboardType="numeric"
                        returnKeyType={'next'}
                        //secureTextEntry
                        onSubmitEditing={() => this.focusNext('instagram')}
                        {...this.getInputProps()}
                      />
                      <TouchableOpacity
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginTop: 16,
                        }}
                        onPress={this.onPressCheck}
                      >
                        <Icon2
                          name={
                            this.state.check
                              ? 'check-box'
                              : 'check-box-outline-blank'
                          }
                          style={{
                            fontSize: L.h(30),
                            color: Colors.iconRegisterWhatsapp,
                          }}
                        />
                        <Text style={localStyle.whatsappFont}>
                          Acepto recibir info por Whatsapp
                        </Text>
                      </TouchableOpacity>
                    </div> */}
                </div>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginTop: 16,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      alignSelf: 'center',
                    }}
                    activeOpacity={0.8}
                    onPress={this.onPressCheck}
                  >
                    <Icon2
                      name={
                        this.state.check
                          ? 'check-box'
                          : 'check-box-outline-blank'
                      }
                      style={{
                        fontSize: L.h(30),
                        color: Colors.iconRegisterWhatsapp,
                      }}
                    />
                    <Text style={localStyle.whatsappFont}>Acepto los</Text>
                  </TouchableOpacity>
                  <TouchableOpacity activeOpacity={0.8} onPress={this.openUrl}>
                    <Text
                      style={[
                        localStyle.whatsappFont,
                        { color: Colors.activeEvent },
                      ]}
                    >
                      {' '}
                      Términos y condiciones
                    </Text>
                  </TouchableOpacity>
                </View>
                <div class="row">
                  <div
                    class="col-sm-12 col-lg-6 offset-lg-3 mt-10"
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <input
                      type="submit"
                      onClick={handleSubmit(this.onRegisterPress.bind(this))}
                      class="custom-button"
                      value={'Registrarme'}
                      style={{
                        marginTop: 20,
                        //width: '50%',
                        alignSelf: 'center',
                      }}
                    />
                  </div>
                </div>
              </View>
            </div>
          </div>
        </section>
        {/* <CustomToast show={showToast} message={toastMessage} type={toastType} /> */}
        {loading ? <Loader text={'Cargando...'} /> : null}
      </View>
    );
  }
}
const localStyle = StyleSheet.create({
  buttonSignIn: {
    backgroundColor: 'white',
    width: screen.width - L.wProm(92),
    alignSelf: 'center',
    height: L.hProm(42),
    marginTop: L.h(6),
    marginVertical: L.h(16),
  },
  buttonSignIntext: {
    fontSize: L.h(14),
    color: Colors.black_87,
    fontFamily: Fonts.MEDIUM,
  },
  calendar: {
    borderTopWidth: 1,
    paddingTop: 5,
    borderBottomWidth: 1,
    borderColor: '#eee',
    height: 350,
  },
  text: {
    textAlign: 'center',
    borderColor: '#bbb',
    padding: 10,
    backgroundColor: '#eee',
  },
  container: {
    flex: 1,
    backgroundColor: 'gray',
  },
  loginSeparator: {
    alignSelf: 'center',
  },
  errorView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgContent: {
    flex: 0.24,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  logo: {
    //width: L.wProm(70),
    //height: L.hProm(20),
    marginTop: L.h(48),
    alignSelf: 'flex-start',
    marginLeft: L.w(150),
    color: 'white',
    fontSize: L.h(16),
    fontFamily: Fonts.DREGULAR,
  },
  linearGradient: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    height: screen.height,
    width: screen.width,
  },
  sloganTxt: {
    fontSize: L.h(18),
    marginHorizontal: L.w(56),
    textAlign: 'left',
    alignSelf: 'flex-end',
    color: 'white',
  },
  form: {
    // marginLeft: L.w(8),
    // marginRight: L.w(4),
  },
  formContainer: {
    // backgroundColor: "white",
    // width: screen.width - L.wProm(48),
    alignSelf: 'center',
  },
  registerButton: {
    height: 60,
    marginTop: 10,
  },
  whatsappFont: {
    color: Colors.textRegisterWhatsapp,
    marginLeft: L.w(8),
    fontSize: 18,
    fontFamily: Fonts.DREGULAR,
  },
});

function mapStateToProps(store) {
  // console.log("storereduxofrm", store.form);
  const selector = formValueSelector('register');
  return {
    appSettings: store.appReducer.appSettings,
    registerValues: selector(
      store,
      'proName',
      'proLastName',
      'proEmail',
      'proPassword'
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actRegister,
      listenerToMyProfile,
      saveDNIDataToUser,
      actLogout,
    },
    dispatch
  );
}
export default reduxForm({
  form: 'register',
  destroyOnUnmount: false,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Register)
);
