export default {
  translation: {
    cardBenefit: {
      typeBenefit: 'Tipo',
      titleBenefit: 'Titulo',
      textPoints: '1000',
      buttonText: 'CANJE',
    },
    cardTicket: {
      mainButton: 'Comprar Tickets',
    },
    eventCard: {
      evtStateActive: 'Activo',
      evtStateUnactive: 'Inactivo',
    },
    eventCardRRPP: {
      evtStateActive: 'Activo',
      evtStateUnactive: 'Inactivo',
    },
    labelCustomInput: {
      placeholder: 'Seleccione',
      back: 'Atrás',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },
    ticketInfo: {
      dialogTitle: 'Abrir mapa',
      dialogMessage: '¿Que aplicación le gustaria usar?',
      cancelText: 'Cancelar',
      whereText: '¿Dónde?',
      hours: 'hs',
    },
    userExistPopUp: {
      ups: 'UPS!',
      mainMessage:
        'El {{message}} ingresado ya está siendo utilizado. Posiblemente hayas ingresado antes con otra cuenta.',
      mainMessage2:
        ' Si el error continua no dudes en contactarnos a nuestro Email ',
    },
    vtFooter: {
      home: 'Home',
      pet: 'Mascotas',
      nfc: 'Mis HDs',
    },
    vtFooterTabs: {
      buys: 'Mis Tickets',
      benefits: 'Beneficios',
      points: 'Puntos',
      profile: 'Perfil',
      events: 'Eventos',
    },
    vtHeader: {
      addOption: 'Ingrese opción ',
    },
    vtLoaderLogo: {
      loaderText: 'Cargando',
    },
    vtOfflineBar: {
      noNetwork: 'No hay conexión a internet',
    },
    vtSideBar: {
      hello: 'Hola extraño!',
    },
    vtSpinner: {
      loading: 'Loading..',
    },
    vtTermsModal: {
      terms: 'Términos y Condiciones ',
      accept: 'Acepto términos y condiciones de ',
      cancel: 'CANCELAR',
      confirm: 'CONFIRMAR',
    },
    sideBarMenuOptions: {
      profile: 'Mi Perfil',
      events: 'Eventos',
      buyHistory: 'Historial de Compras',
      settings: 'Settings',
    },
    activateAccount: {
      loaderText: 'Enviando...',
      sendTextTitle: 'Se envio el mail correctamente a ',
      sendTextConfirm: 'Aceptar',
    },
    forgotPassword: {
      forgotPasswordError: 'Usuario no encontrado.',
      forgotPasswordAcceptButtonError: 'Aceptar',
      recoveryPassword: 'Recuperar contraseña',
      emailLabel: 'Email',
      sendButton: 'ENVIAR',
    },
    loginMain: {
      loaderText: 'Cargando...',
      userCancelledErrorText: 'User cancelled request',
      userCancelledWrongAccessText:
        'Something went wrong obtaining the users access token',
      enterWith: 'O inicia sesión con redes sociales',
      continueWithFacebook: 'Continuar con Facebook',
      continueWithGoogle: 'Continuar con Google',
      omit: 'Omitir',
    },
    register: {
      birthDate: 'Fecha de nacimiento',
      passwordSuccesfullyUpdated: 'Actualización sactisfactoria',
      errorOcurred: 'Ups, ocurrió un problema',
      reSendEmail: 'Volver a enviar Email',
      accountActivate: 'Ya active mi cuenta, ingresar',
      scanData: 'Confirma todos los campos escaneados',
      activateAccount: {
        resendConfirm: 'Confirmación de Reenvío.',
        reloginMsg:
          'El email fue enviado nuevamente, reloguee luego de verificar la cuenta.',
        active: 'Active',
        yourAcc: 'su cuenta',
        activeMsg1: 'Para comenzar a utilizar la aplicación debe activar',
        activeMsg2: 'su cuenta desde el enlace que le enviamos en',
        activeMsg3: 'el e-mail de confirmación de registro.',
        mailError: '¿No recibió el mail de activación?',
        mailSend: 'Se ha enviado un e-mail con el enlace de activación',
        mailMsg: 'Si ya activó la cuenta por e-mail, ingrese nuevamente!',
      },
      signInSteps: {
        title: 'Registrarme',
        textBePart: 'Ahora podés ser parte de nuestra App',
        textIntro: 'Checkea tu casilla de correo para activar tu cuenta',
        sendEmailTxt: 'Hemos enviado un e-mail de verificación a ',
        spamText:
          'Si no lo ves en tu bandeja de entrada, te pedimos que revises  spam o correo no deseado',
        textChooseOption:
          'Selecciona alguna de estas opciones para el registro',
        textScan: 'Scanea tu DNI',
        textOr: 'O bien',
        textLoadManually: 'Carga tus datos manualmente',
        textCompleteFields: 'Completa o confirma todos los campos',
        textFewMore: 'Sólo unos datos más!',
        textCongrats: 'Felicitaciones,',
        textPartOf: 'ya sos parte del club!',
      },
      loaderText: 'Registrando...',
      successRegisterText: 'Se ha registrado con éxito',
      accept: 'Aceptar',
      registerTitle: 'REGISTRO',
      registerBack: 'Atrás',
      registerNameLabel: 'Nombre',
      registerSurnameLabel: 'Apellido',
      registerDniLabel: 'DNI',
      registerGenderLabel: 'Género (opcional)',
      registerGenderManLabel: 'Masculino',
      registerGenderWomLabel: 'Femenino',
      registerEmailLabel: 'Email',
      registerPasswordLabel: 'Password',
    },
    scanDataRegister: {
      loaderText: 'Verificando...',
      modalTextError: 'Debes ser mayor de edad para poder registrarte',
      modalTextError2: 'Ya existe un usuario registrado con tu DNI.',
      modalTextNotMe:
        '¿Desea borrar la cuenta existente para continuar con su registro?',
      modalTextValidateAccount:
        'Por favor, iniciá sesión y validá tu cuenta desde Mi Cuenta',
      modalTextButton: 'Borrar',
      modalTextButtonNo: 'No',
      modalTextButtonAccept: 'Aceptar',
      scanDataDNI: 'DNI',
      scanDataName: 'Nombre',
      scanDataSurname: 'Apellido',
      scanDataBirthdate: 'Fecha de Nacimiento',
      scanDataGenderMan: 'Masculino',
      scanDataGenderWoman: 'Femenino',
    },
    scanner: {
      scanerTitle: 'Escanea el código del frente de tu documento',
    },
    validateCode: {
      sendCode: 'Enviar Código',
      reSendCode: 'Reenviar Código',
      validateSuccess: 'Teléfono validado correctamente',
      validateError: 'Código ingresado incorrecto',
      validateButtonOk: 'OK',
      validateMainText:
        'Te enviamos el código a tu número de teléfono. Ingresalo a continuación',
      validateMainText2: 'Si no recibís el mensaje en 2 minutos, presioná',
      validateMainText3: 'REENVIAR SMS',
      validateMainButton: 'Validar',
      validateRecivedCode: 'Ingrese el código recibido',
      validateRecivedCode2: 'desde el envío del código',
    },
    validatePhone: {
      sendCode: 'Enviar Código',
      validateError: 'Ups! Hubo un error, porfavor verificá su teléfono',
      telephone: 'Teléfono',
      register: 'Registrarse',
      enterPhoneNumber:
        'Ingresá o confirmá tu número de teléfono y te enviaremos un código para validarlo',
      enterPhoneNumber2: 'característica + número sin 0 ni 15',
      validatePhoneMainButton: 'Continuar',
    },
    detailBenefits: {
      loaderText: 'Cargando...',
      headerTitle: 'Detalle',
      mainText: 'Tenés',
      mainText2: 'puntos acumulados',
      exchange: 'CANJEAR',
      benefit: 'Beneficio',
      howAddPoints: '¿Cómo sumo puntos?',
      conditions: 'Condiciones',
      errorExchangeBenefit: 'Ups ocurrio un error.',
      errorExchangeBenefit2: 'Ups, no tenes puntos suficiente.',
      modalOkButton: 'OK',
      successExchangeBenefit: 'Canje exitoso!',
    },
    mainBenefits: {
      mainText: 'Tenés',
      mainText2: 'puntos acumulados',
      exchangeYourPoints: 'Canjeá tus puntos por',
      exchangeYourPointsBenefits: 'beneficios!',
    },
    camera: {
      cameraPermissions: 'Permiso para tomar fotos con la cámara',
      cameraPermissionsUsage: 'Utiliza tu cámara para tomar fotos y subirlas.',
    },
    notifications: {
      title: 'Notificaciones',
      leavingApp: 'Estás a punto de salir de la aplicación',
      notificationEmptyState:
        'Oh! Parece que no tienes notificaciones para mostrar.',
      leavingAppAccept: 'Aceptar',
      leavingAppCancel: 'Cancelar',
    },
    statisticsDetail: {
      ticketInSell: 'Entradas en venta',
      ticketSoldByRrpp: 'Vendidas por RRPP',
      ticketReferencedByRrpp: 'Referenciadas por RRPP',
      ticketSoldByRrppAmount: 'Vendidas por RRPP Monto',
      ticketSoldByProducer: 'Vendidas por Productor',
      ticketSoldByProducerAmount: 'Vendidas por Productor Monto',
      sellByApp: 'Venta por Aplicación',
      sellByAppAmount: 'Venta por Aplicación Monto',
      alreadySold: 'Vendidas',
      alreadySoldAmount: 'Vendidas Monto',
      invites: 'Invitaciones',
      exchanges: 'Canjes',
      amountForProducer: 'Ganancia del productor',
      statisticLabel: '*Las comisiones y los gastos ya están discriminados',
      statisticTitle: 'Estadísticas',
      overdueOrUnactiveEvent: 'El evento está vencido o Inactivo',
      accept: 'Aceptar',
      overdueButton: 'VENCIDO',
      alreadyToSell: 'VENDER',
      organize: 'ORGANIZA',
      resume: 'RESUMEN TOTAL',
      detailByTicketType: 'Detalle por tipo de entradas',
      overdueEvent: 'VENCIDO',
    },
    statisticsMain: {
      actives: 'Activos',
      unactives: 'Inactivos',
      yourEvents: 'Estos son tus eventos',
      welcome: 'Hola ',
    },
    statisticsProducer: {
      mainText: 'Se rinden a $',
      rrppName: 'Nombre de RRPP',
      soldByRrpp: 'Vendidas por RRPP',
      directSell: 'Venta directa sin RRPP',
      sold: 'Vendidas',
      amountForProducer: 'Ganancia del productor',
      statisticLabel: '*Las comisiones y los gastos ya están discriminados',
      rrpp: 'RRPP',
      rrppSold: 'RRPP Vendidas',
      qntity: 'Cantidad',
      ticket: 'Ticket',
      shouldReport: 'Debe rendir',
      loadingData: 'Cargando Datos',
      total: 'TOTALES',
    },
    statisticsRRPP: {
      mainText: 'Se rinden a $',
      rrppName: 'Nombre de RRPP',
      soldByRrpp: 'Vendidas por RRPP',
      directSell: 'Venta directa sin RRPP',
      sold: 'Vendidas',
      amountForProducer: 'Ganancia del productor',
      statisticLabel: '*Las comisiones y los gastos ya están discriminados',
      rrpp: 'RRPP',
      rrppSold: 'RRPP Vendidas',
      referencedSold: 'Ventas referenciadas',
      qntity: 'Cantidad',
      ticket: 'Ticket',
      shouldReport: 'Debe rendir',
      loadingData: 'Cargando Datos',
      total: 'TOTALES',
    },
    editEmail: {
      fabError: 'Debe modificar el email antes de guardar.',
      requiredFields: 'Complete los campos requeridos!',
      password: 'Contraseña',
      ok: 'OK',
      save: 'Guardar',
      updateEmail: 'Actualizar E-mail',
    },
    editPassword: {
      requiredFields: 'Debe completar todos los campos.',
      currentPassword: 'Contraseña Actual',
      newPassword: 'Nueva Contraseña',
      save: 'Guardar',
      password: 'Contraseña',
    },
    editProfile: {
      name: 'Nombre',
      phone: 'Teléfono',
      instagram: 'Tu Instagram sin @',
      surname: 'Apellido',
      password: 'Contraseña',
      successUpdateProfile: 'Cambios guardados correctamente!',
      buttonText: 'OK',
      error: 'Ocurrio un Error',
      requiredFields: 'Complete los campos requeridos!',
      documentType: 'Tipo de documento',
      dni: 'DNI',
      documentNumber: 'Número de documento',
      gender: 'Género',
      genderMan: 'Masculino',
      genderWoman: 'Femenino',
      save: 'Guardar',
      edit: 'Editar',
      personalData: 'Datos Personales',
    },
    profileMain: {
      closeSession: 'Cerrar sesión',
      deleteAccount: 'Eliminar Cuenta',
      myProfile: 'Mi Perfil',
      profile: 'Perfil',
      updateEmail: 'Actualizar E-mail',
      updatePassword: 'Modificar Contraseña',
      deleteAccountLabel:
        'Estas seguro que deseas eliminar tu cuenta? Todos tus datos y tickets se perderan.',
      cancelButton: 'Cancelar',
      close: 'Cerrar',
      loaderText: 'Cargando...',
      chooseOption: 'Seleccione una opción',
      takePhoto: 'Tome una foto',
      choosePhoto: 'Seleccione de la galería…',
      verifiedUser: 'Usuario verificado',
      unverifiedUser: 'Usuario no verificado',
      yourPoints: 'Tus puntos',
      yourBuysPoints: 'Tus compras suman puntos!',
      consult: 'Por consultas o soporte contactarse a ',
      consult2: 'Por consultas o soporte, envíe un mail a ',
      appVersion: 'App Version: ',
      error: 'Ups ocurrio un error.',
      successDelete: 'Tu cuenta fue eliminada exitosamente.',
    },
    mainRrpp: {
      yourEvents: 'Estos son tus eventos',
      welcome: 'Hola ',
      rrpp: ' RRPP ',
    },
    rrppDetail: {
      totalTickets: 'Tickets totales',
      referenced: 'referenciados',
      referenced2: 'Referenciadas',
      sold: 'vendidos',
      sold2: 'Vendidas',
      amountForProducer: 'Monto a rendir a Productor',
      title: 'Evento',
      unactiveEvent: 'El evento esta vencido o Inactivo',
      accept: 'Aceptar',
      overdue: 'VENCIDO',
      sell: 'VENDER',
      organize: 'ORGANIZA ',
      totalResume: 'RESUMEN TOTAL DE SUS VENTAS',
      soldTickets: 'Tickets Vendidos',
      referencedTickets: 'Tickets Referenciados',
      totalAmountProducer: 'TOTAL a rendir a Productor',
      detailByTicketType: 'Detalle por tipo de tickets',
      invites: 'Invitaciones',
      commisionDetail: '*Las comisiones y los gastos ya están discriminados',
    },
    transferTicketRRPP: {
      errorDNISearch: 'No podes hacer una busqueda con tu DNI',
      errorSell: 'No se pudo realizar la venta',
      successSell: 'Venta exitosa',
      sellTickets: 'Vender Ticket',
      total: 'Total $',
      attention:
        'ATENCIÓN: La persona a la que se le vende los tickets debe tener en su teléfono la app de',
      attention2: 'App y estar previamente registrado.',
      registeredBuyer: 'DNI de comprador registrado',
      search: 'Buscar',
      dni: 'DNI',
      ticketSellLabel:
        'Una vez vendidos los tickets, estos aparecerán en la cuenta del comprador',
      noMoreTickets: 'No quedan tickets disponibles',
      selectTicketType: 'Seleccione tipo de ticket',
      selectTicketTypeToSell: 'Seleccione tipo de ticket a vender',
      selectRrpp: 'Seleccione RRPP (Opcional)',
      selectEvent: 'Seleccione evento',
      selectQtity: 'Seleccione la cantidad',
      soldOut: 'SOLD OUT',
      availables: 'Disponibles:',
      sellTickets0: 'Vender tickets costo $0',
      sellTicket: 'Vender Ticket',
      sellTickets2: 'Está a punto de vender los siguientes tickets',
      errorRequiredFields: 'Falta completar campos',
      accept: 'Aceptar',
      cancel: 'Cancelar',
      pay: 'rendir $',
      loaderText: 'Buscando...',
      loaderConfirmText: 'Procesando...',
      errorUserSearch: 'Usuario no encontrado.',
      sell: 'Vender',
      confirmTicketSell:
        '¿Confirmar venta {{qnty}} tickets con un valor de ${{price}} a {{proName}} {{proLastName}}?',
      confirmTicketNoRegisterSell:
        'Usuario No registrado:\n¿Confirmar venta de {{qnty}} tickets con un valor de ${{price}} al DNI:{{proDNI}}?',
    },
    shopDetail: {
      redime: 'REDIMIDO',
      transfered: 'TRANSFERIDO',
      transfer: 'TRANSFERIR',
      ticket: 'TICKET',
      overdueEvent: 'EVENTO VENCIDO',
      orverdueTicket: 'TICKET VENCIDO',
      operationCode: 'Código de operación',
      ticketValid: 'Ticket válido en puerta sólo para',
      presentDNI: 'Recordá presentar este ticket + tu DNI',
    },
    shopMain: {
      yourTickets: 'Tus tickets',
      ticketBought: 'ticket adquirido',
      ticketsBought: 'tickets adquiridos',
      processingTicketPayment: 'Procesando Pago de Tickets',
      processingTicketPayment2:
        'Estamos Procesando tu Pago, intenta en unos minutos.',
      emptyState: ' No tienes eventos para mostrar',
    },
    transferTicket: {
      errorSearchDNI: 'No podés hacer una búsqueda con tu DNI',
      accept: 'Aceptar',
      loaderText: 'Buscando...',
      errorSearchUser: 'Usuario no encontrado.',
      loaderTextTransfer: 'Transfiriendo...',
      transferTicket: 'Transferir Ticket',
      transferingTicket: 'Está a punto de transferir el siguiente ticket',
      attention:
        'ATENCIÓN: La persona a la que se transfiera la entrada debe tener en su teléfono la app de',
      errorTransfer: 'No podes transferir el ticket a vos mismo.',
      attention2: 'App y estar registrada.',
      dniToTransfer: 'Documento a quien transferir',
      findDNI: 'Buscar',
      transferLabel:
        'Una vez transferido el ticket a la persona, este aparecerá automáticamente en su cuenta',
      errorTransfer2:
        'La persona a la que quieres transferir no esta verificada.',
    },
    cardTypeSelect: {
      loaderText: 'Cargando...',
      errorOcurred: 'Ups ocurrio un error.',
      boughtTickets: 'Compraste tickets con tus puntos!',
      noEnoughPoints: 'Ups, no tenes puntos suficiente.',
      doPay: 'Realizar Pago',
      selectPaymentMethod: 'Seleccione el método de pago',
      fintechMethods:
        'MercadoPago, UALA, Lemon Chash, Tarjetas de Billeteras Virtuales',
      debitCard: 'Tarjeta de Débito',
      creditCard: 'Tarjeta de Crédito',
      internationalCard: 'Tarjeta de Crédito o Débito no emitida en Argentina',
      payPoints: 'Pagá con Puntos',
    },
    pagos360Screen: {
      loaderText: 'Cargando...',
      pay360: 'Pagos 360',
    },
    stripeScreen: {
      doPay: 'Realizar Pago',
      selectPaymentMethod: 'Seleccione el método de pago',
      payWithStripe: 'Pagá con Stripe',
    },
    ticketDetail: {
      soldOut: 'SOLD OUT',
      availables: 'Disponibles',
      conditions: 'Condiciones del evento',
      close: 'CERRAR',
      infoTitle: 'Info',
      readEventConditions: 'Revisá las condiciones del evento',
      total: 'Total',
      buyTickets: 'Comprar Tickets',
      verifiedAccount:
        'Su cuenta debe estar verificada para adquirir tickets en este evento, para mas info contactenos a ',
      verifiedAccount2:
        'o envienos un MP a nuestras redes Instagram: @leparcguemes o @vlack.oficial, Facebook: Leparcguemes',
      login: 'Debe iniciar sesión para realizar esta acción',
      loginPopup: 'Ingresar',
      loginCancel: 'Cancelar',
      errorStock: 'Sin stock de entradas',
      errorSelectTickets: 'Debes seleccionar entradas.',
      errorMustLogin: 'Debe iniciar sesión para realizar esta acción',
    },
    ticketFinal: {
      boughtTicket: 'Ticket Adquirido',
      congrats: 'Felicitaciones {{user}}, tu compra ha sido aprobada!',
      operationCode: 'Este es tu código de operación de compra',
      attention:
        'ATENCIÓN: Recuerda transferir cada entrada a los otros asistentes desde',
      myBoughts: '“Mis Tickets',
      presentDNI:
        'Esto es un requisito necesario. Cada asistente debe presentar en puerta su',
      presentDNI2: 'propia entrada en',
      presentDNI3: 'APP + DNI',
      details: 'Detalle de tickets adquiridos',
    },
    ticketResume: {
      loaderText: 'Cargando...',
      failedBuy: 'Fallo la compra, contactarse con ',
      complete: 'Completar',
      cancel: 'Cancelar',
      title: 'Compra',
      rrpp: 'RRPP',
      selectRRPP: 'Seleccione RRPP (Opcional)',
      paymentInfo: 'Información de pago',
      validInfo: 'Debe ingresar informacion valida.',
      failedPreference: 'Fallo la creación de la preferencia.',
      securePayment: 'Recuerde que esta compra es segura.',
      selectTickets: 'Debes seleccionar entradas.',
      checkoutProcess: 'Proceso de checkout para compra de evento:',
      total: 'Total:',
      addModal:
        'Para continuar con la compra es necesario que completes lo datos faltantes de tu perfil',
    },
    walletMain: {
      yourPoints: 'Tus puntos',
      lastMovements: 'Últimos movimientos',
      exchanged: 'Canjeaste por ',
    },
    home: {
      welcome: 'Bienvenido a ',
      loaderText: 'Cargando...',
      instagram: 'Tu Instagram sin @',
      addInstagram: 'Recordá ingresar tu Instagram para una mejor experiencia',
      addInstagram2: 'para finalizar, es necesario registrar tu Email',
      accept: 'Aceptar',
      endRegister: 'Finalizar registro',
      cancel: 'Cancelar',
      email: 'Email',
      addInstagramIOS: 'Ingrese un ',
      addInstagramIOS2: 'válido, recuerde que no debe incluir espacios',
      successRegister: 'Perfecto!',
      successRegister2: 'Ahora estás listo para comprar tu primera entrada',
      start: 'Comenzar',
    },
    mainView: {
      home: 'Inicio',
      settings: 'Config',
      messages: 'Alertas',
      friends: 'Contactos',
      myBoughts: 'Mis Tickets',
      profile: 'Perfil',
      benefits: 'Beneficios',
      events: ' ',
      nextEvents: 'Próximos Eventos',
      points: 'Puntos',
      login: 'Debe iniciar sesión para realizar esta acción',
      loginButton: 'Ingresar',
      loginButtonCancel: 'Cancelar',
    },
    global: {
      add: 'Agregar',
      age: 'Edad',
      all: 'Todas',
      all2: 'Todos',
      actual: 'Actual',
      addPassport: ' Cargar pasaporte',
      address: 'Domicilio',
      after: 'Siguiente',
      allService: 'Todos los servicios',
      and: 'Y',
      apply: 'Aplicar',
      are: 'Son',
      area: 'Área',
      awards: 'Sorteos y eventos',
      back: 'Volver',
      bank: 'Banco',
      before: 'Anterior',
      behind: 'Atrás',
      benefits: 'Comprá fácil y rápido',
      bonus: 'Bonificación',
      branchs: 'Sucursales',
      buy: 'Comprar',
      call: 'Llamar',
      checkCardNumber: 'Revisar Nº de tarjeta',
      cancel: 'Cancelar',
      cancelPay: 'Políticas de cancelación',
      capture: 'Capturar',
      card: 'Tarjeta',
      cardExpiration: 'Vencimiento Tarjeta',
      cardNumber: 'Nº de tarjeta',
      changeCountry: 'Cambiar país',
      changePayment: ' Elegir otra forma de pago',
      checkData: 'Revisar datos',
      city: 'Ciudad',
      clubAlmundo: 'Club Almundo',
      close: 'Cerrar',
      connectionProblems:
        'Hay problemas con la conexión. Intentá ingresar más tarde.',
      contact: 'Contacto',
      continue: 'Continuar',
      completeField: 'Complete el campo',
      coin: 'Moneda',
      currency: 'ARS',
      country: 'País',
      dates: 'Fechas',
      dateOffer: 'Fechas en oferta',
      day: 'Día',
      days: 'Días',
      delete: 'Eliminar',
      deletedData: 'Dato eliminado',
      deletedPhone: 'Teléfono eliminado',
      deleteFile: 'Eliminar dato',
      deletePassaport: 'Eliminar pasaporte',
      deletePhone: 'Eliminar teléfono',
      deletedCompanion: 'Compañero eliminado',
      description: 'Descripción',
      detail: 'Detalle',
      devolution: 'Entrega',
      discounts: 'Sumá puntos por tus compras',
      dolar: 'USD',
      edit: 'Editar',
      editBirthday: 'Editar fecha de nacimiento',
      editCard: 'Editar tarjeta',
      editDate: 'Editar fecha',
      editName: 'Editar nombres',
      editNumber: 'Editar número',
      editSearch: 'Modificar búsqueda',
      end: 'Fin',
      enterEmail: 'Ingresá un e-mail válido.',
      enterPassword: 'Ups, ingresá una contraseña.',
      email: 'E-mail',
      errorAction: 'Ups, no pudimos subir tu foto :(',
      errorProvider: 'No se pudo realizar la acción.',
      experiences: 'Reservas',
      expiration: 'Vencimiento',
      expirationDate: 'Fecha de vencimiento',
      expired: 'Vencida',
      experts: 'Expertos',
      facebook: 'Facebook',
      fem: 'Femenino',
      filter: 'Filtrar',
      finish: 'Finalizar',
      flight: 'Vuelos',
      free: 'Gratis',
      from: 'A partir de',
      general: 'General',
      gender: 'Género',
      getInto: 'Ingresar',
      getTerms: 'Para continuar, aceptá los términos y  condiciones',
      google: 'Google',
      have: 'Tenés',
      hellowExtr: '¡Hola, extraño!',
      hi: 'Hola',
      hiExtr: 'Hola, extraño',
      hide: 'Ocultar',
      hs: 'hs',
      includes: 'Incluye',
      ID: 'DNI',
      idType: 'Tipo de Documento',
      ilustrativeImg: 'Imagen ilustrativa',
      in: 'En',
      inconvenient: '¡Ups!',
      inconvenientSearch: '¡Ups! Ocurrió un error durante la búsqueda.',
      insidePhone: '0810 333 1153',
      iReadAccept: 'Leí y acepto',
      itineraryConditions: 'Por favor, tienes que aceptar el itinerario',
      later: 'Más Tarde',
      landingPlaces: 'Asesoramiento en destino',
      likeIt: '¿Te gusta Almundo?',
      list: 'Lista',
      loading: 'Cargando',
      map: 'Mapa',
      max: 'Máximo',
      max2: 'Máx.',
      masc: 'Masculino',
      menu: 'Menú',
      min: 'Mínimo',
      month: 'Mes',
      months: 'Meses',
      more: 'Más',
      moreRelevant: 'Más relevantes',
      mustUpdateTitle: 'Aplicación desactualizada',
      mustUpdate: 'Debes actualizar la aplicación para continuar',
      nacionality: 'Nacionalidad',
      night: 'Noche',
      nights: 'Noches',
      noConnect:
        'Parece que hay problemas con tu conexión. Intentá ingresar más tarde.',
      notifications: 'Notificaciones',
      number: 'Número',
      numbers: 'Ingresá 11 números.',
      numbers2: 'Ingresá 7 u 8 caracteres numéricos',
      of: 'De',
      off: 'Off',
      offer: 'Ofertas',
      ok: 'Listo',
      onlyLetters: 'Ingresá solo letras',
      onlyNumbers: 'Ingresá solo números',
      opinionInteresting:
        'Tu opinión nos interesa. Dinos cuánto disfrutas nuestra app y dejá una reseña en Play Store.',
      opinionInteresting2:
        'Tu opinión nos interesa. Dinos cuánto disfrutas nuestra app y dejá una reseña en App Store.',
      opinions: 'Opiniones',
      optionNoAvailable: 'Opción no disponible',
      or: 'O podés',
      order: 'Ordenar',
      orderTitle: 'ORDENAR',
      originNull: 'Ups, no volamos ahí',
      ourBranchs: 'Nuestras sucursales',
      outsidePhone: '0810 333 1153',
      otherPhones: 'Otros teléfonos',
      obligatoryField: 'Campo obligatorio',
      obligatoryField2: 'Obligatorio',
      passenger: 'Pasajero',
      passengers: 'Pasajeros',
      passaport: 'Pasaporte',
      password: 'Contraseña',
      passwordLenght: 'Entre 8 y 16 caracteres',
      payment: 'Pago',
      payment2: 'Tarjeta ingresada como garantía',
      paymentMethod: 'Tipo de pago',
      people: 'Persona',
      peoples: 'Personas',
      phone: 'Teléfono',
      phoneType: 'Tipo de télefono',
      phoneCountryCod: '54',
      phoneCountryCodLength: 'Entre 1 y 3 caracteres',
      phoneAreaCodeLength: 'Entre 1 y 4 caracteres',
      phoneLength: 'Entre 5 y 11 caracteres',
      points: 'puntos',
      price: 'Precio',
      priceAsc: 'Precio más bajo',
      priceDesc: 'Precio más alto',
      principal: 'Principal',
      processing: 'Procesando solicitud...',
      promotions: 'Bonificaciones',
      privacyPolicy: 'Políticas de privacidad',
      rate: 'Calificar',
      ready: 'Listo',
      recents: 'Recientes',
      reservation: 'Reserva',
      reservations: 'Reservas',
      retirement: 'Retiro',
      return: 'Devuelvo',
      retrying: 'Reintentar',
      save: 'Guardar',
      search: 'Buscar',
      searching: 'Buscando',
      seeAll: 'Ver todos',
      seeAll2: 'Ver todas',
      seePromotions: 'Ver bonificaciones',
      services: 'Servicios',
      select: 'Seleccionar',
      selected: 'Seleccionada',
      send: 'Enviar',
      searchAgain: 'Realizar nueva búsqueda',
      settings: 'Ajustes',
      several: 'Varias',
      show: 'Mostrar',
      since: 'Desde',
      socialMedia: 'Redes Sociales',
      start: 'Inicio',
      termsConditions: 'Términos y condiciones',
      termsConditions2:
        'Aceptá los términos y condiciones para poder finalizar la reserva.',
      timeout:
        'Ups, esto está tardando demasiado.\nDanos una segunda oportunidad y volvé a intentarlo.',
      trips: 'Mis viajes',
      to: 'A',
      the: 'El',
      type: 'Tipo',
      total: 'Total',
      toThe: 'Al',
      ufa: '¡Ufa!',
      understood: 'Entendido',
      unlink: 'Desvincular',
      undo: 'Deshacer',
      until: 'Hasta',
      update: 'Actualizar',
      upgrade: 'Actualizar',
      upgradeVersion: '¡Hay una nueva versión!',
      upgradeInfo: 'Actualizá la aplicación para un mejor rendimiento',
      validCUIL: 'Ingresá un CUIL válido',
      validCUIT: 'Ingresá un CUIT válido',
      week: 'Semana',
      weeks: 'Semanas',
      with: 'Con',
      year: 'Año',
      years: 'Años',
      accept: 'Aceptar',
      yes: 'Sí',
      no: 'No',
      whoIsGoingToFly: '¿Quienes van a viajar?',
      checkPassengers: 'Elegí los pasajeros',
      errorApplication: 'Ups, hubo un error.',
      errorApplication2: 'Volvé a intentar en unos minutos.',
    },
    login: {
      forgotMyPass: '¿Olvidaste tu contraseña?',
      sloganA: 'Innovating',
      sloganB: 'your businness',
      signIn: 'Iniciar sesión',
      signUp: '¿No tenés cuenta? Registrate',
      logOut: 'Cerrar sesión',
      welcome: 'Hola!',
      buttonLogin: 'Iniciar Sesión',
      buttonRegister: '¿No tenés cuenta?, Registrate!',
      orText: 'O inicie sesión con',
      loginError: 'Error de login',
      userNot: 'No se encontró el usuario',
      mailNot: 'E-mail inválido',
      passNot: 'Contraseña inválida',
      register: {
        modalError: 'Falló el registro',
        forgotPassword: 'Olvidó su contraseña?',
      },
      userNotRegistered: 'Usuario y/o Contraseña incorrecta',
      wrongPassword: 'Usuario y/o Contraseña incorrecta',
      invalidEmail: 'Correo inválido',
      weakPassword: 'La contraseña debe tener 6 caracteres como mínimo',
      emailInUse: 'Este correo ya esta en uso',
      verifyYourConection: 'Compruebe su conexión a internet',
    },
  },
};
