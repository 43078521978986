import PaymentService from "../provider/payment/PaymentService";
import _ from "lodash";

export function onCreatePreference(total, email, product, rrppId, callback) {
  return (dispatch, getStore) => {
    PaymentService.createPreference(total, email, product, rrppId)
      .then((productPaymentData) => {
        callback(productPaymentData);
      })
      .catch((e) => {
        callback(e);
      });
  };
}
export function onCreateStripePreference(total, email, product, rrppId, callback) {
  return (dispatch, getStore) => {
    PaymentService.createStripePreference(total, email, product, rrppId)
      .then((productPaymentData) => {
        callback(productPaymentData);
      })
      .catch((e) => {
        callback(e);
      });
  };
}

export function onPressExchangeBenefitButton(benefit, callback) {
  return (dispatch, getStore) => {
    PaymentService.loyalyExchangeBenefit(benefit)
      .then((benefitData) => {
        callback(benefitData);
      })
      .catch((e) => {
        callback({ error: e });
      });
  };
}
export function buyTicketWithPoints(data, callback) {
  return (dispatch, getStore) => {
    PaymentService.buyTicketWithPoints(data)
      .then((benefitData) => {
        callback(benefitData);
      })
      .catch((e) => {
        callback({ error: e });
      });
  };
}

export function getPaymentInformation(paymentId, callback) {
  return (dispatch, getStore) => {
    PaymentService.getPaymentInformation(paymentId)
      .then((paymentInfo) => {
        callback(paymentInfo);
      })
      .catch((e) => {
        callback(e);
      });
  };
}

export function startToListen(buyData) {
  return (dispatch, getStore) => {
    asyncListenPaymentCollection(buyData, (response) => {
      dispatch({
        type: "SAVE_EVENT_PAYMENT_INFORMATION",
        eventPaymentData: response,
      });
    });
  };
}

async function asyncListenPaymentCollection(buyData, callback) {
  try {
    let response = await PaymentService.listenerEvents(buyData, callback);
    return response;
  } catch (error) {}
}
export function successfulPay(buyData, callback) {
  return (dispatch, getStore) => {
    PaymentService.pay(buyData)
      .then((payment) => {
        callback(payment);
      })
      .catch((e) => {
        callback(e);
      });
  };
}

export function getPaymentHistory(userId, isCurrent) {
  return (dispatch, getStore) => {
    asyncGetPaymentHistory(userId, isCurrent).then((data) => {
      if (isCurrent) {
        dispatch({
          type: "SAVE_PAYMENT_HISTORY_END_DATE",
          paymentEndDate: data,
        });
      } else {
        dispatch({
          type: "SAVE_PAYMENT_HISTORY",
          paymentHistory: data,
        });
      }
    });
  };
}

async function asyncGetPaymentHistory(userId, isCurrent) {
  let response = PaymentService.getPaymentHistory(userId, isCurrent);
  return response;
}
