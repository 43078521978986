import React, { Component } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  Image,
  Platform,
} from "react-native";
import I18n from "i18next"
import { Icon } from "native-base";
import { Actions } from "react-native-router-flux";
import IconFA from "react-native-vector-icons/FontAwesome";
import FastImage from "react-native-fast-image";
import Colors from "../common/Colors";
import LinearGradient from "react-native-linear-gradient";
import L from "../common/Layout";
import Fonts from "../common/Fonts";
import directionIcon from "../common/assets/directionIcon.png";
import MainButton from "../components/MainButton";

class CardTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: Dimensions.get("window").width,
      height: Dimensions.get("window").height,
    };
  }

  componentWillMount() {
    Dimensions.addEventListener("change", (e) => {
      const { width, height } = e.window;
      this.setState({ width, height });
    });
  }

  componentWillUnmount() {
    Dimensions.removeEventListener("change");
  }

  renderStar(ranking, colorStar) {
    let stars = [];
    let count = 0;
    let sum = 0;
    for (let val in ranking) {
      sum += ranking[val];
      count++;
    }
    let total = sum / count;
    for (let i = 1; i < 6; i++) {
      if (i <= total) {
        stars.push(
          <Icon
            key={i}
            name="ios-star"
            style={{ color: colorStar, fontSize: 13 }}
          />
        );
      } else {
        stars.push(
          <Icon
            key={i}
            name="ios-star-outline"
            style={{ color: "white", fontSize: 13 }}
          />
        );
      }
    }
    return stars;
  }

  calculateRanking(ranking) {
    let count = 0;
    let sum = 0;
    for (let val in ranking) {
      sum += ranking[val];
      count++;
    }
    let total = sum / count;
    return total;
  }

  renderFirstImage(images) {
    let url = "";

    for (let key in images) {
      if (images[key].status === 1) {
        url = images[key].url;
        break;
      }
    }

    return url;
  }

  /*
  <div class="col-sm-6 col-lg-4">
                                  <div class="event-grid">
                                      <div class="movie-thumb c-thumb">
                                          <a href="event-details.html">
                                              <img src={event.item.evtImg} alt="event"/>
                                          </a>
                                          <div class="event-date">
                                              <h6 class="date-title">28</h6>
                                              <span>Dec</span>
                                          </div>
                                      </div>
                                      <div class="movie-content bg-one">
                                          <h5 class="title m-0">
                                              <a href="event-details.html">{event.item.evtTitle}</a>
                                          </h5>
                                          <div class="movie-rating-percent">
                                              <span>{formatedDate}</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              */

  /*
  <TouchableOpacity
                                    activeOpacity={1}
                                    style={[ 
                                    ]}
                                    onPress={() => Actions.push("ticketDetail", { event: event })}
                                  >
                                  */
render() {
                                const { numRows, event } = this.props;
                                const opacity = 1;
                                return (
                                  
                                          <div class="col-sm-6 col-lg-4">
                                            <TouchableOpacity
                                    activeOpacity={1}
                                    style={[ 
                                    ]}
                                    onPress={() => Actions.push("ticketDetail", { event: event })}
                                  >
                                  <div class="event-grid">
                                      <div class="movie-thumb c-thumb">
                                          <a >
                                              <img src={this.props.img} alt="event"/>
                                          </a>
                                          <div class="event-date">
                                              <h6 class="date-title">28</h6>
                                              <span>Dec</span>
                                          </div>
                                      </div>
                                      <div class="movie-content bg-one">
                                          <h5 class="title m-0">
                                              <a >{this.props.title.toUpperCase()}</a>
                                          </h5>
                                          <div class="movie-rating-percent">
                                              <span>{this.props.date}</span>
                                          </div>
                                      </div>
                                  </div>
                                  </TouchableOpacity>
                              </div> 
                                );
                              }
  
 renderMobile() {
    const { numRows, event } = this.props;
    const opacity = 1;
    return (
      <TouchableOpacity
        activeOpacity={1}
        style={[
          styles.container,
          { opacity, height: this.state.width / numRows },
        ]}
        onPress={() => Actions.push("ticketDetail", { event: event })}
      >
        <FastImage
          style={styles.cardTicket}
          source={{
            uri: this.props.img,
            headers: { Authorization: "someAuthToken" },
            priority: FastImage.priority.high,
          }}
          resizeMode={FastImage.resizeMode.contain}
        >
          {(Platform.OS == 'web') ? null : ( //WEB99 TODOWEB

            <View style={styles.eventName}>
            <View style={styles.titleTicket}>
              <Text
                numberOfLines={2}
                ellipsizeMode="tail"
                style={styles.titleTicketFont}
              >
                {this.props.title.toUpperCase()}
              </Text>
            </View>
          </View>

          )

          }
          
        </FastImage>
        <View style={styles.baseContainer}>
          <View style={styles.locationContainer}>
            <View style={styles.ownerTicket}>
              <Image source={directionIcon} style={{ marginRight: 5 }} />
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={styles.locationText}
              >
                {this.props.place}
              </Text>
            </View>
            <View style={styles.ownerTicket}>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={{
                  color: Colors.dateTextHome,
                  fontFamily: Fonts.MEDIUM,
                }}
              >
                {this.props.date}
              </Text>
              <IconFA
                name="calendar"
                type="FontAwesome"
                style={{
                  color: Colors.secondary_color,
                  fontSize: 13,
                  marginLeft: 7,
                }}
              />
            </View>
          </View>
          <View>
            <MainButton
              text={I18n.t("cardTicket.mainButton")}
              onPress={() => Actions.push("ticketDetail", { event: event })}
            />
          </View>
        </View>
        <LinearGradient
          colors={[
            Colors.gradientColorSeparatorSeven,
            Colors.gradientColorSeparatorSix,
            Colors.gradientColorSeparatorFive,
            Colors.gradientColorSeparatorFour,
            Colors.gradientColorSeparatorThree,
            Colors.gradientColorSeparatorTwo,
          ]}
          start={{ x: 0.0001, y: 0 }}
          end={{ x: 1.05, y: 0 }}
          style={{ height: 10 }}
        />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    //alignItems: "center",
    flex: 1,
    //borderBottomColor: Colors.primary_color,
    //borderBottomWidth: 10,
    backgroundColor: Colors.backgroundTicketsHome,
  },
  cardTicket: {
    width: "100%",
    height: "100%",
    maxHeight: L.h(247),
    justifyContent: "space-between",
    //backgroundColor: "#eee",
    overflow: "hidden",
  },
  eventName: {
    position: "absolute",
    width: "100%",
    bottom: 0,
  },
  titleTicket: {
    paddingHorizontal: L.w(25),
    paddingVertical: L.h(15),
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  titleTicketFont: {
    color: Colors.paleGrey,
    fontSize: L.h(17),
    fontFamily: Fonts.DSEMIBOLD,
    textAlign: "center",
  },
  locationText: {
    color: Colors.secondary_color,
    fontFamily: Fonts.DREGULAR,
  },
  ownerTicket: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  baseContainer: {
    paddingHorizontal: L.w(20),
    backgroundColor: Colors.homeBaseContainer,
    paddingVertical: L.h(15),
  },
  locationContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: L.h(19),
  },
});

export default CardTicket;
